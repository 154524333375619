import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import './VerticalTimelineElement.css';

import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import SchoolIcon from '@mui/icons-material/School';
import StarIcon from '@mui/icons-material/Star';
import WorkIcon from '@mui/icons-material/Work';

export default function ResumeElement(props) {
	var foreground = '#fff';
	var background,icon;

	switch(props.type)
	{
		case 'work':
			background = 'rgb(33, 150, 243)';
			icon = <WorkIcon />;
			break;
		case 'education':
			background = 'rgb(16, 204, 82)';
			icon = <SchoolIcon />;
			break;
		case 'award':
			background = 'rgb(233, 30, 99)';
			icon = <StarIcon />;
			break;
		default:
			background = 'rgb(150, 150, 42)';
			icon = <QuestionMarkIcon />;
			break;
	}
	
	var masterClass = "resume-vertical-element--" + props.type;
	var iconStyle = { background: background, color: '#fff' };
	var contentStyle = null;
	var contentArrowStyle = null;
	
	if (props.highlight)
	{
		contentStyle = { background: background, color: foreground };
		contentArrowStyle = { borderRight: '7px solid ' + background };
		masterClass += " resume-vertical-element-highlight";
	}
	

	
	return (
		<VerticalTimelineElement 
			className={masterClass}
			contentStyle={contentStyle}
			contentArrowStyle={contentArrowStyle}
			date={props.date}
			iconStyle={iconStyle}
			icon={icon}>
			<h3 className="vertical-timeline-element-title">{props.title}</h3>
			<h4 className="vertical-timeline-element-subtitle">{props.organization}</h4>
			<h4 className="vertical-timeline-element-subtitle">{props.location}</h4>
			<p>{props.children}</p>
		</VerticalTimelineElement>
	);
}